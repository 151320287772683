<template>
  <div id="condition-report-move-in-out">
    <SdDiscardChangesDialog
      v-if="uiFlags.leavingToRouteNext !== null"
      message="Are you sure you want to leave and discard changes?"
      @close="uiFlags.leavingToRouteNext = null"
      @discard="reset()"
    />
    <div
      v-show="stepsState.active <= stepsState.submit && stepsState.active > 0"
      class="steps-wrapper-transition"
      :style="transition.styles"
    />
    <div
      v-show="stepsState.active <= stepsState.submit && stepsState.active > 0"
      class="steps-wrapper"
    >
      <div
        class="step-wrapper"
        :class="getStepClass(1)"
        @click="selectStep(1)"
      >
        <span class="step-icon">1</span>
        <span class="step-title d-none d-md-block">General</span>
      </div>
      <div
        class="step-wrapper"
        :class="getStepClass(2)"
        @click="selectStep(2)"
      >
        <span class="step-icon">2</span>
        <span class="step-title d-none d-md-block">Access instructions</span>
      </div>
      <div
        class="step-wrapper"
        :class="getStepClass(3)"
        @click="selectStep(3)"
      >
        <span class="step-icon">3</span>
        <span class="step-title d-none d-md-block">Order summary</span>
      </div>
    </div>
    <SdPage>
      <SdLoadingLayer :init="init">
        <template v-slot:loaded>
          <ElForm
            ref="conditionReportForm"
            :model="payload"
            :rules="rulesRef"
            :validate-on-rule-change="false"
            class="row align-items-center justify-content-center"
          >
            <div class="col-12 col-md-6">
              <ConditionReportGeneralHeader
                :property="propertyRef"
                :report-type="payload.type"
              />
              <div
                v-show="stepsState.active === 1"
              >
                <ConditionReportMoveInOutGeneralInfo
                  :condition-report="listingDataPrefillRef"
                  :business="businessRef"
                  :property="propertyRef"
                  :unit="selectedUnitRef"
                  :payload="payload"
                  @update-payload="updatePayload"
                />
              </div>
              <div
                v-if="stepsState.active >= 2"
                v-show="stepsState.active === 2"
              >
                <h2 />
                <div class="font-21 pb-2">
                  Access instructions
                </div>
                <ConditionReportAccessInstructions
                  :property="propertyRef"
                  :payload="payload"
                  @update-payload="updatePayload"
                />
              </div>
              <div
                v-show="stepsState.active === 3"
              >
                <div class="font-21 pb-3">
                  Order Summary
                </div>
                <ConditionReportOrderSummary
                  :payload="payload"
                  @update-payload="updatePayload"
                />
              </div>
              <div class="col-12 py-3" />
              <div class="text-right">
                <ElButton
                  :class="{'full-width-button': stepsState.active === stepsState.submit}"
                  :disabled="!(stepsState.active < stepsState.submit) && !payload.finalConfirmChecked"
                  :loading="submittingData"
                  type="primary"
                  size="medium"
                  @click="nextStep"
                >
                  {{ stepsState.active < stepsState.submit ? 'Next' : 'Confirm order' }}
                </ElButton>
              </div>
              <ConditionReportOrderSuccessDialog
                ref="conditionReportOrderSuccessDialog"
                :payload="payload"
              />
            </div>
          </ElForm>
        </template>
      </SdLoadingLayer>
    </SdPage>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import get from 'lodash.get';
import { showErrorNotify } from '@/utils/NotifyUtil';
import {
 reactive, ref, watch, onMounted,
} from '@vue/composition-api';
import ConditionReportGeneralHeader from '@/components/condition-report/ConditionReportGeneralHeader';
import ConditionReportMoveInOutGeneralInfo, { generalInfoRules } from '@/components/condition-report/ConditionReportMoveInOutGeneralInfo';
import ConditionReportAccessInstructions, { accessInstructionsRules } from '@/components/condition-report/ConditionReportAccessInstructions';
import ConditionReportOrderSummary from '@/components/condition-report/ConditionReportOrderSummary';
import momentUtil from '@/utils/MomentUtil';
import ConditionReportOrderSuccessDialog from '@/components/condition-report/ConditionReportOrderSuccessDialog';
import SdDiscardChangesDialog from '@/components/common/form/SdDiscardChangesdDialog';

export default {
  name: 'ConditionReportMoveInOut',
  components: {
    ConditionReportOrderSummary,
    ConditionReportMoveInOutGeneralInfo,
    ConditionReportAccessInstructions,
    ConditionReportGeneralHeader,
    ConditionReportOrderSuccessDialog,
    SdDiscardChangesDialog,
  },
  beforeRouteLeave(to, from, next) {
    // show discard changes model when leaving between access instructions and activation steps
    if (this.stepsState.active > 1 && this.stepsState.active <= this.stepsState.submit) {
      this.uiFlags.leavingToRouteNext = next;
    } else {
      next();
    }
  },
  setup(props, context) {
    const store = context.root.$store;
    const propertyRef = ref({});
    const selectedUnitRef = ref({});
    const submittingData = ref(false);
    const transition = reactive({ styles: 'width:33%;border-radius: 0 50px 50px 0;' });
    const stepsState = reactive(defaultStepsState);
    const uiFlags = reactive({ leavingToRouteNext: null });
    const rulesRef = ref(generalInfoRules);
    const payload = reactive(defaultPayload);
    const businessRef = ref({});
    const listingDataPrefillRef = ref(null);
    let nextPressed = false;

    watch(() => stepsState.active, changeTransition);
    onMounted(() => {
      payload.type = context.root.$route.meta.reportType;
    });
    return {
      init,
      stepsState,
      nextStep,
      rulesRef,
      payload,
      propertyRef,
      selectedUnitRef,
      updatePayload,
      selectStep,
      getStepClass,
      transition,
      businessRef,
      listingDataPrefillRef,
      getActiveStepRules,
      submittingData,
      reset,
      uiFlags,
    };

    function openSuccessDialog() {
      context.refs.conditionReportOrderSuccessDialog.open();
    }

    function reset() {
      Object.keys(payload).forEach((index) => payload[index] = null);
      uiFlags.leavingToRouteNext();
      uiFlags.leavingToRouteNext = null;
      this.stepsState.active = 1;
    }

    function getStepClass(stepNumber) {
      let classes = '';
      if (stepNumber === stepsState.active) {
        classes += ' is-active';
      }
      if (stepNumber < stepsState.active) {
        classes += ' is-processed';
      }
      return classes;
    }

    function changeTransition() {
      transition.styles = `width:${Math.ceil(stepsState.active * 33)}%;border-radius: 0 50px 50px 0;`;
    }

    async function init() {
      if (context.root.$route.params.id) {
        propertyRef.value = await store.dispatch(
          'Property/find',
          context.root.$route.params.id,
        );
        payload.unit_id = context.root.$route.params.unit_id;

        payload.propertyName = propertyRef.value.short_address;
        if (payload.unit_id) {
          const unitId = Number(payload.unit_id);
          selectedUnitRef.value = propertyRef.value.units.find((unit) => unit.id === unitId);
          if (selectedUnitRef.value.name) {
            payload.propertyName = `${payload.propertyName} - ${selectedUnitRef.value.name}`;
          }
        }
      }
    }

    async function nextStep() {
      nextPressed = true;
      await selectStep(stepsState.active + 1);
    }

    async function selectStep(newIndex) {
      async function setStep() {
        if (newIndex <= stepsState.submit) {
          stepsState.active = newIndex;
        } else {
          await submitData();
        }
        nextPressed = false;
      }
      try {
        if (newIndex > stepsState.active) {
          context.refs.conditionReportForm.validate(async (valid) => {
            if (valid) {
              await setStep();
            }
          });
        } else {
          await setStep();
        }
      } catch (error) {
        if (!(error instanceof Error)) {
          // Validator failed
        }
      }
      getActiveStepRules();
    }

    function getActiveStepRules() {
      const rules = [
        generalInfoRules,
        accessInstructionsRules,
        {},
      ];
      rulesRef.value = rules[stepsState.active - 1];
      payload.currentRules = { ...rulesRef.value };
    }

    function updatePayload(fields) {
      let needToReevaluate = false;
      Object.keys(fields).forEach((key) => {
        if (!needToReevaluate && fields[key]) {
          needToReevaluate = true;
        }
        payload[key] = fields[key];
      });
      if (needToReevaluate && nextPressed) {
        context.refs.conditionReportForm.validate();
      }
      calculateDate();
    }

    function calculateDate() {
      if (payload.selected_date && payload.window_hour) {
        const [start, end] = payload.window_hour.split('-');
        const readFormat = 'MMM D, YYYY';
        const serverFormat = 'YYYY-MM-DD HH:mm:ss';
        const parseFormat = `${readFormat} hA`;

        const browserTimezone = momentUtil.tz.guess();
        const propertyTimezone = propertyRef.value.timezone;

        const offset = ` ${ momentUtil(null, propertyTimezone).format('Z')}`;

        const startDate = moment(payload.selected_date, [readFormat]).tz(browserTimezone).format(readFormat);
        payload.startDate = moment(`${startDate} ${start}`, [parseFormat]).format(serverFormat) + offset;
        payload.endDate = moment(`${startDate} ${end}`, [parseFormat]).format(serverFormat) + offset;

        payload.displaySelectedDate = momentUtil(payload.selected_date, browserTimezone).toDisplayFormatMonthDayAndYearSt();
      }
    }

    async function submitData() {
      submittingData.value = true;
      // first check for duplicates
      try {
        await context.root.$store.dispatch('ConditionReport/checkForDuplicate', {
          unit_id: payload.unit_id,
          type: payload.type,
        });
        // no duplicates, submit report request
        try {
          payload.createdReport = await store.dispatch('ConditionReport/store', {
            lock_box_code: payload.lock_box_code,
            access_instructions: payload.access_instructions,
            instructions: payload.instructions,
            preferred_from: payload.startDate,
            preferred_until: payload.endDate,
            unit_id: Number(payload.unit_id),
            bedrooms: payload.unit_bedrooms,
            type: payload.type,
          });
          openSuccessDialog();
        } catch (error) {
          const errorMessage = get(error, 'response.data.message', error.message);
          const errorType = get(error, 'response.data.error');
          // some error in submitting/creating the report
          showErrorNotify(context, errorMessage);
          if (errorType && errorType === 'preferred_from') {
            await selectStep(1);
          }
          submittingData.value = false;
        }
      } catch (error) {
        // found a duplicate
        showErrorNotify(context, error.message, { duration: 0 });
        submittingData.value = false;
      }
    }
  },
};

const defaultStepsState = {
  active: 1,
  submit: 3,
};

const defaultPayload = {
  lock_box_code: '',
  access_instructions: '',
  instructions: '',
  selected_date: null,
  unit_bedrooms: null,
  window_hour: null, // e.g '11am-3pm'
  agreementChecked: false,
  unit_id: null,
  price: null,
  displaySelectedDate: null,
  finalConfirmChecked: false,
  currentRules: {},
};
</script>

<style lang="scss">
#condition-report-move-in-out {
  .steps-wrapper {
    width: 100%;
    left: 0;
    position: absolute;
    background: gray-color(lighter);
    display: flex;
  }
  .step-wrapper {
    flex-basis: 33%;
    height: 48px;
    cursor: pointer;
    z-index: 1;
    justify-content: center;
    display: flex;
    align-items: center;
    &.is-active,
    &.is-processed {
      .step-icon {
        background: $white;
        color: theme-color(primary);
      }
      .step-title {
        color: $white;
      }
    }
    &.is-processed {
      .step-icon {
        color: transparent;
      }
      .step-icon:before {
        content: "\e6da";
        font-family: 'element-icons' !important;
        color: theme-color(primary);
        padding: 0 5px;
      }
    }
  }
  .steps-wrapper-transition {
    transition: all 0.5s ease-in-out;
    z-index: 1;
    height: 48px;
    left: 0;
    position: absolute;
    background: theme-color(primary);
  }
  .step-icon {
    border-radius: 50%;
    background: gray-color();
    width: 24px;
    color: $white;
    height: 24px;
    text-align: center;
  }
  .step-title {
    margin-left: 16px;
    color: gray-color();
  }
  .page {
    padding-top: 5rem;
  }
  .short-address {
    flex: 1;
    margin-right: -1.5rem;
  }
  .full-width-button {
    width: 100%;
  }
}
</style>
