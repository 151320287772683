<template>
  <ElFormItem>
    <ElTooltip
      :visible-arrow="false"
      popper-class="popover-panel"
      placement="right-start"
      :disabled="$viewport.lt.md"
    >
      <template slot="content">
        <i class="sdicon-info-circle" />
        <p>{{ tooltipTextRef }}</p>
      </template>
      <ElFormItem :prop="fieldNameRef">
        <SdFloatLabel>
          <ElInput
            v-model="generalNotesPayloadRef"
            type="textarea"
            :rows="4"
            :placeholder="placeholderText"
            @input="emitChange"
          />
        </SdFloatLabel>
      </ElFormItem>
    </ElTooltip>
  </ElFormItem>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';

export default {
  name: 'GeneralNotes',
  components: {},
  props: {
    generalNotes: {
      type: String,
      required: false,
      default: null,
    },
    tooltipText: {
      type: String,
      required: true,
      default: '',
    },
    placeholderText: {
      type: String,
      required: true,
      default: '',
    },
    fieldName: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const generalNotesPayloadRef = ref(props.generalNotes);
    const tooltipTextRef = ref(props.tooltipText);
    const fieldNameRef = ref(props.fieldName);
    onMounted(emitChange);
    return {
      generalNotesPayloadRef,
      tooltipTextRef,
      fieldNameRef,
      emitChange,
    };

    function emitChange() {
      context.emit('update:generalNotes', generalNotesPayloadRef.value);
      context.emit('change', { general_notes: generalNotesPayloadRef.value });
    }
  },
};
</script>
