<template>
  <ElDialog
    id="condition-report-order-success-dialog"
    :center="true"
    :visible.sync="isVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :width="$viewport.lt.md ? '100vw': '500px'"
    :fullscreen="$viewport.lt.md"
  >
    <div class="text-center">
      <div class="text-center mb-3">
        <div class="d-inline-flex justify-content-center align-items-center">
          <img src="@/assets/mailbox.svg">
        </div>
      </div>
      <div class="font-21 mb-2 font-weight-strong">
        Report ordered successfully
      </div>
      <div class="font-17">
        {{ payload.propertyName }}
      </div>
      <div class="font-17">
        Report ID #{{ get(payload.createdReport, 'id') }}
      </div>
      <div class="d-flex justify-content-center align-items-center" />
    </div>
    <div
      slot="footer"
      class="row gutter-1 justify-content-center"
    >
      <div class="col-md-6 col">
        <ElButton
          class="w-100"
          size="medium"
          :loading="uiFlags.orderAnotherButtonLoading"
          @click="orderAnotherReport"
        >
          Order another one
        </ElButton>
      </div>
      <div class="col-md-6 col">
        <ElButton
          class="w-100"
          type="primary"
          size="medium"
          :loading="uiFlags.doneButtonLoading"
          @click="closeDialog"
        >
          Done
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import get from 'lodash.get';

export default {
  name: 'ConditionReportOrderSuccessDialog',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { $router, $route } = context.root;
    const location = window.location;
    const uiFlags = reactive({
      doneButtonLoading: false,
      orderAnotherButtonLoading: false,
    });

    const isVisible = ref(false);

    return {
      isVisible,
      open,
      orderAnotherReport,
      uiFlags,
      get,
      closeDialog,
    };

    function open() {
      isVisible.value = true;
    }

    function closeDialog() {
      uiFlags.doneButtonLoading = true;
      location.replace('/condition-reports?tab=requested');
    }

    function orderAnotherReport() {
      uiFlags.orderAnotherButtonLoading = true;
      location.replace('/properties/units/condition-report/add');
    }
  },
};
</script>

<style lang="scss">
#condition-report-order-success-dialog {
  .el-dialog {
      > .el-dialog__body {
        flex: 1;
        padding: 1rem 2rem 1rem;
      }
    .el-dialog__header {
      display: none;
    }
  }
}
</style>
