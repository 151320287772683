<template>
  <div
    id="condition-report-order-summary"
    class="row"
  >
    <div class="col-12">
      <div class="payment-summary p-3">
        <div class="font-17 pb-3">
          ${{ payloadRef.price }} due for a
          <template v-if="reportType === 'vacancy_check'">
            {{ ReportHumanNames[payload.type] }}
          </template>
          <template v-else>
            {{ ReportHumanNames[payload.type] }} report
          </template>
        </div>
        <div class="payment-method font-13">
          To be paid upon completion, charged from your account payment method<br>
          (ending ****{{ get(card, 'last4') }})
        </div>
      </div>
      <div class="py-3">
        <li>
          <span class="bullet">
            {{ whenString }}
          </span>
        </li>
      </div>
      <div class="checkbox-agree pl-0">
        <ElCheckbox
          v-model="payloadRef.finalConfirmChecked"
          label="By submitting this form, I give Showdigs Agents permission to access
          the property to conduct a condition report. I am also authorizing Showdigs
          to charge my credit card for this service."
          class="pt-2 pr-3"
          @change="emitPayloadChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api';
import get from 'lodash.get';
import { ConditionReport, ReportHumanNames } from '@/constants/ConditionReport';

export default {
  name: 'ConditionReportOrderSummary',
  components: {},
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const payloadRef = ref(props.payload);
    const card = ref({});
    const reportType = context.root.$route.meta.reportType;
    const whenString = computed(computeWhenString);
    init();
    return {
      payloadRef,
      emitPayloadChanged,
      card,
      get,
      whenString,
      ReportHumanNames,
      ConditionReport,
      reportType,
    };

    function computeWhenString() {
      if (reportType === ConditionReport.REPORT_TYPE_IN_OUT) {
        return `The report will be conducted on ${props.payload.displaySelectedDate} ${props.payload.window_hour}`;
      }
      if (reportType === ConditionReport.REPORT_TYPE_PERIODIC) {
        return 'The report will be conducted within 10 days given tenant availability.';
      }
      if (reportType === ConditionReport.REPORT_TYPE_VACANCY_CHECK) {
        return `The report will be conducted on ${props.payload.displaySelectedDate}`;
      }
      return '';
    }

    function emitPayloadChanged() {
      context.emit('update-payload', {
        finalConfirmChecked: payloadRef.value.finalConfirmChecked,
      });
    }

    async function init() {
      const paymentSources = await store.dispatch('Billing/getPaymentSources');
      card.value = paymentSources.find((source) => source.type === 'card').card;
    }
  },
};

</script>

<style lang="scss">
  #condition-report-order-summary {
    .payment-summary {
      border: 1px solid gray-color('light');
      box-sizing: border-box;
      border-radius: 6px;
      .payment-method {
        line-height: 20px;
      }
    }
    .checkbox-agree {
      .el-checkbox__label {
        white-space: normal;
        line-height: 1.375rem;
        font-size: $--font-size-small;
      }
      .el-checkbox__input {
        vertical-align: top;
        top: 3px;
      }
    }
    &.el-dialog {
      border-radius: 20px;
    }
  }
</style>
