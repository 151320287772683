export default {
  TWO_HOURS_OPEN_HOUSE: 'two_hours_open_house',
  ONE_HOUR_OPEN_HOUSE: 'one_hour_open_house',
  SHOWING_SERVICE_ACCESS_ISSUE: 'showing_service_access_issue',
  CONDITION_REPORT_ACCESS_ISSUE: 'condition_report_access_issue',
  AGENT_TOUR: 'agent_tour',
  VACANT_PROPERTY: 'vacant_property',
  PERIODIC: 'periodic',
  MOVE_IN_OUT_SMALL: 'move_in_out_small',
  MOVE_IN_OUT_MEDIUM: 'move_in_out_medium',
  MOVE_IN_OUT_LARGE: 'move_in_out_large',
};
