<template>
  <div class="row move-in-general-info">
    <div class="col-12">
      <p class="font-21">
        Floorplan
      </p>
      <div class="row mb-4">
        <div class="col-12 col-md-4">
          <ElFormItem
            prop="unit_bedrooms"
          >
            <SdFloatLabel label="Bedrooms">
              <ElSelect
                v-model="reportPayloadRef.unit_bedrooms"
                placeholder="Bedrooms"
                @input="emitUnitPayloadChanged()"
              >
                <ElOption
                  v-for="(bedroomOption, bedroomOptionIndex) in bedroomsOptions"
                  :key="bedroomOptionIndex"
                  :label="bedroomOption"
                  :value="bedroomOption"
                />
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-4">
          <ElFormItem
            prop="unit_bathrooms"
            :rules="{ type: 'enum', enum: bathroomsOptions }"
          >
            <SdFloatLabel label="Bathrooms">
              <ElSelect
                v-model.number="unit.bathrooms"
                placeholder="Bathrooms"
                clearable
                @input="emitUnitPayloadChanged()"
              >
                <ElOption
                  v-for="(bathroomOption, bathroomOptionIndex) in bathroomsOptions"
                  :key="bathroomOptionIndex"
                  :label="bathroomOption"
                  :value="bathroomOption"
                />
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-4">
          <ElFormItem
            prop="unit_size"
            :rules="{ type: 'integer', min: 0, max: 199999, message: 'May be empty or between 0 and 199999' }"
          >
            <SdFloatLabel label="Size">
              <ElInput
                v-if="!unit.listing"
                v-model.number="unit.size"
                v-no-scroll
                type="number"
                step="1"
                placeholder="Size"
                clearable
                @input="emitUnitPayloadChanged()"
              >
                <template slot="append">
                  sq. ft.
                </template>
              </ElInput>
              <ElInput
                v-else
                :value="`${new Intl.NumberFormat().format(unit.size)} sq. ft.`"
                placeholder="Size"
                disabled
              />
            </SdFloatLabel>
          </ElFormItem>
        </div>
      </div>
      <div
        v-if="unit.bedrooms"
        class="row mb-4"
      >
        <div class="col">
          <div class="col-12 w-100 condition-report-cost">
            <div class="pt-3">
              <div class="pl-0">
                Condition report cost:
              </div>
              <div
                v-if="['1', 'Studio'].includes(reportPayloadRef.unit_bedrooms)"
                class="col-12"
              >
                <div
                  class="col-12 col-md-7 pl-0 pt-2 pr-0 mr-0 font-21 font-weight-strong"
                >
                  ${{ prices[MoveInOutReportTypes.ONE_BD_OR_STUDIO] }} for 1 bedroom properties
                </div>
                <div
                  v-if="$viewport.gt.xs"
                  class="position-absolute small-apt"
                >
                  <SdArt
                    class="art"
                    art="showing"
                    size="80px"
                  />
                </div>
              </div>
              <div
                v-else-if="['2', '3'].includes(reportPayloadRef.unit_bedrooms)"
              >
                <div
                  class="col-9 pl-2 pt-2 font-21 font-weight-strong"
                >
                  ${{ prices[MoveInOutReportTypes.TWO_TO_THREE_BD] }} for 2-3 bedroom properties
                </div>
                <div
                  v-if="$viewport.gt.xs"
                  class="position-absolute large-apt"
                >
                  <SdArt
                    class="art"
                    art="big-property"
                    size="120px"
                  />
                </div>
              </div>
              <div
                v-else-if="reportPayloadRef.unit_bedrooms > 3"
              >
                <div
                  class="col-9 pl-2 pt-2 font-21 font-weight-strong"
                >
                  ${{ prices[MoveInOutReportTypes.FOUR_BD_PLUS] }} for 4+ bedroom properties
                </div>
                <div
                  v-if="$viewport.gt.xs"
                  class="position-absolute large-apt"
                >
                  <SdArt
                    class="art"
                    art="big-property"
                    size="120px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ElDivider class="mt-5 mt-md-4" />
      <p class="font-21 mb-3">
        Report Timing
      </p>
      <p>
        We'll need a 4-hour window, and we're able to fulfil the report at least 3 days in advance
      </p>
    </div>
    <div class="col-12 col-md-4 pt-0">
      <ElFormItem prop="selected_date">
        <SdFloatLabel>
          <ElDatePicker
            ref="datePicker"
            v-model="reportPayloadRef.selected_date"
            type="date"
            :picker-options="{disabledDate}"
            :clearable="false"
            align="left"
            class="availability-date-picker"
            format="dd MMMM"
            placeholder="Select a date"
            @change="emitPayloadChanged"
          />
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <div class="col-12 col-md-5 pt-md-0">
      <ElFormItem prop="window_hour">
        <SdFloatLabel>
          <ElSelect
            v-model="reportPayloadRef.window_hour"
            class="availability-date-picker"
            placeholder="Select a window hour"
            @change="emitPayloadChanged"
          >
            <ElOption
              v-for="range in hoursOptions"
              :key="range"
              :value="range"
              :label="range"
            />
          </ElSelect>
        </SdFloatLabel>
      </ElFormItem>
    </div>
    <ElDivider />
    <p class="col-12 font-21 py-0">
      Special instructions
    </p>
    <GeneralNotes
      class="col-12 pt-0"
      :general-notes.sync="reportPayloadRef.instructions"
      tooltip-text="Any additional instructions to our agent or emphasis on items to review during the report"
      placeholder-text="Notes or any specific things the agent should check"
      field-name="instructions"
      @change="emitPayloadChanged"
    />
    <div
      class="col-12 pt-4"
    >
      <ElFormItem
        id="checkbox-agreement"
        prop="agreementChecked"
      >
        <ElCheckbox
          v-model="reportPayloadRef.agreementChecked"
          label="By ordering the report, I confirm that the property will be ready for review
          and no other vendors or contractors will be present. In case the Showdigs agent
          will not be able to perform the report at the chosen time, a $50 fee will still
          be charged."
          class="col-auto confirm-checkbox text-gray-darker"
          @change="emitPayloadChanged"
        />
      </ElFormItem>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { getPricesValueByKey } from '@/utils/PriceUtil';
import { MoveInOutReportTypes } from '@/constants/ConditionReport';
import { bedroomsOptions, bathroomsOptions } from '@/constants/Unit';
import GeneralNotes from '@/components/common/GeneralNotes';
import Price from '@/constants/Price';
import momentUtil from '../../utils/MomentUtil';

const earliestDay = momentUtil(null, momentUtil.tz.guess()).startOf('day').add(4, 'days').toDate();

const hoursOptions = ['9am-1pm', '10am-2pm', '11am-3pm', '12pm-4pm', '1pm-5pm', '2pm-6pm'];

export default {
  name: 'ConditionReportMoveInOutGeneralInfo',
  components: { GeneralNotes },
  props: {
    property: {
      type: Object,
      default: null,
    },
    unit: {
      type: Object,
      default: null,
    },
    payload: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const prices = {
      [MoveInOutReportTypes.ONE_BD_OR_STUDIO]: getPricesValueByKey(Price.MOVE_IN_OUT_SMALL, props.property.market_id),
      [MoveInOutReportTypes.TWO_TO_THREE_BD]: getPricesValueByKey(Price.MOVE_IN_OUT_MEDIUM, props.property.market_id),
      [MoveInOutReportTypes.FOUR_BD_PLUS]: getPricesValueByKey(Price.MOVE_IN_OUT_LARGE, props.property.market_id),
    };
    const reportPayloadRef = ref(props.payload);
    reportPayloadRef.value.unit_bedrooms = props.unit.bedrooms ? props.unit.bedrooms : null;

    return {
      reportPayloadRef,
      emitPayloadChanged,
      disabledDate,
      hoursOptions,
      prices,
      formValidations,
      MoveInOutReportTypes,
      bedroomsOptions,
      bathroomsOptions,
      emitUnitPayloadChanged,
    };

    async function submit() {
      await context.refs.selectPropertyForm.validate();
      emitPayloadChanged();
    }

    function disabledDate(date) {
      return date < earliestDay;
    }

    function emitPayloadChanged() {
      let price = null;
      if (['Studio', '1'].includes(reportPayloadRef.value.unit_bedrooms)) {
        price = prices[MoveInOutReportTypes.ONE_BD_OR_STUDIO];
      } else if (['2', '3'].includes(reportPayloadRef.value.unit_bedrooms)) {
        price = prices[MoveInOutReportTypes.TWO_TO_THREE_BD];
      } else if (reportPayloadRef.value.unit_bedrooms > 3) {
        price = prices[MoveInOutReportTypes.FOUR_BD_PLUS];
      }

      context.emit('update-payload', {
        selected_date: reportPayloadRef.value.selected_date,
        window_hour: reportPayloadRef.value.window_hour,
        instructions: reportPayloadRef.value.instructions,
        agreementChecked: reportPayloadRef.value.agreementChecked,
        unit_bedrooms: reportPayloadRef.value.unit_bedrooms,
        price,
      });
    }

    async function emitUnitPayloadChanged() {
      props.unit.bedrooms = reportPayloadRef.value.unit_bedrooms;

      await context.root.$store.dispatch('Unit/update', {
        unitId: props.unit.id,
        unit: props.unit,
      });

      emitPayloadChanged();
    }
  },
};

export const generalInfoRules = {
  selected_date: [
    {
      required: true,
      message: 'Date is reqired',
    },
  ],
  unit_bedrooms: [
    {
      type: 'enum',
      enum: bedroomsOptions,
      required: true,
      message: 'Bedroom count is required',
    },
  ],
  window_hour: [
    {
      required: true,
      message: 'Window hour is reqired',
    },
  ],
  agreementChecked: [
    {
      required: true,
      type: 'enum',
      enum: ['true'],
      message: 'Agreement checked is required',
      transform: (value) => value.toString(),
    },
  ],
};

const formValidations = {
  ...generalInfoRules,
};
</script>

<style lang="scss">
.move-in-general-info {
  #report_date_time_pickers {
    .el-input__prefix {
      display: none;
    }
    .el-form-item__error {
      left: 15px;
      top: 80%;
    }
  }

  .confirm-checkbox {
    background-color: theme-color('primary-superlight');
    border-radius: $app-border-radius;
    padding: 22px;
    .el-checkbox__label {
      white-space: normal;
      line-height: 1.375rem;
    }
    .el-checkbox__input {
      vertical-align: top;
      top: 2px;
    }
  }
  #checkbox-agreement {
    .el-form-item__error {
      top: 95%;
    }
  }
  .availability-date-picker {
    width: 100% !important;
    .el-input__prefix {
      display: none;
    }
    .el-input__inner {
        padding-left: 1rem;
    }
  }
  .condition-report-cost {
    background-color: theme-color('primary-superlight');
    height: 100px;
    border-radius: $app-border-radius;
  }
  .small-apt {
    right: 19px;
    top: -25px;
  }
  .large-apt {
    right: 20px;
    top: 6px;
  }
}

</style>
