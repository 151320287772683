import store from '@/store';

export function getPricesValueByKey(key, marketId = null) {
  const prices = store.getters['Prices/prices'];
  const price = prices.find((price) => price.key === key && Number(price.market_id) === marketId);

  if (!price) {
    const matchedPrice = prices.find((price) => price.key === key);
    if (!matchedPrice) {
      return null;
    }
    return Math.round(matchedPrice.amount);
  }

  return Math.round(price.amount);
}
