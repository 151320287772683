<template>
  <div class="row">
    <div class="col-md-12 pt-2">
      <div
        :class="{'pb-4': $viewport.gt.sm}"
      >
        <span class="font-28 d-flex">
          <template v-if="reportType==='vacancy_check'">
            {{ reportName }}
          </template>
          <template v-else>
            {{ reportName }} report
          </template>
        </span>
        <span
          class="font-17 font-weight-strong pt-2"
        >
          {{ propertyName }}
        </span>
      </div>
      <div
        v-if="$viewport.gt.sm"
        class="d-flex row py-4"
      >
        <div class="heading-border" />
      </div>
      <ElDivider v-else />
    </div>
  </div>
</template>

<script>
import { ReportHumanNames } from '@/constants/ConditionReport';

export default {
  name: 'ConditionReportGeneralHeader',
  components: {},
  props: {
    property: {
      type: Object,
      default: null,
    },
    reportType: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const reportName = ReportHumanNames[props.reportType];
    let propertyName = props.property.short_address;
    if (context.root.$route.params.unit_id) {
      const unitId = Number(context.root.$route.params.unit_id);
      const selectedUnit = props.property.units.find((unit) => unit.id === unitId);
      if (selectedUnit.name) {
        propertyName = `${propertyName} - ${selectedUnit.name}`;
      }
    }
    return {
      reportName,
      propertyName,
      props,
    };
  },
};

</script>

<style lang="scss" scoped>
.heading-border {
  position: absolute;
  left: calc(-111%);
  width: 100vw;
  border-bottom: 1px solid gray-color(light);
}
</style>
